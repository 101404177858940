import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'

interface ExperimentFlags {
  [key: string]: Array<string> | undefined
}

type ExperimentVariant = {
  ref: Ref<boolean>
  flagName: string
  expectedValue: string
}

export const useAbStore = defineStore('ab', () => {
  const isLoading = ref(true)
  const experiments = {
    // for example
    // PM350: { ref: ref(true), flagName: "pm350variant", expectedValue: "A" },
    PM604: { ref: ref(true), flagName: 'pm604variant', expectedValue: 'A' },
  }

  const initExperiment = (experiment: ExperimentVariant) => {
    window.ymab('metrika.88769417', 'getFlags', (flags: ExperimentFlags) => {
      if (!flags[experiment.flagName]) return

      experiment.ref.value = flags[experiment.flagName]?.[0] === experiment.expectedValue
    })
  }

  const initAllExperiments = () => {
    Object.values(experiments).forEach(initExperiment)
  }

  return {
    // for example
    // isPM350VariantA: experiments.PM350.ref
    isPM640VariantA: experiments.PM604.ref,
    // for example
    // initPM350Experiment: () => initExperiment(experiments.PM350),
    initPM604Experiment: () => initExperiment(experiments.PM604),
    initAllExperiments,
    isLoading,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAbStore, import.meta.hot))
}